* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

h1 {
  font-family: 'Courier Prime', monospace;
  font-size: 2.5em;
  margin-bottom: 0.5em;
  text-align: left;
}

h2 {
  color: #8acd01;
  margin: 0.5em 0;
}

img {
  border-radius: 5px;
  background-color: #1a1a1a;
  max-width: 90%;
}

span { 
  background-color: transparent;
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.title {
  background-color: transparent;
  margin: -1px 40px 5px 40px;
  height: 25vh; 
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  color: #1a1a1a; 
  font-size: 10vw;
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: default;
  filter: brightness(100%) blur(0.7px);
}

.title:hover {
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #8acd01;
}

.About, .Skills {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 7px -1px;
  border: solid 0.5px #1a1a1a;
  padding: 30px 80px;
}

.About > div {
  margin: 20px;
}

.About > img {
  border: solid 4px #8acd01;
  width: 420px;
  max-width: 90%;
}

.About > div > p {
  margin-bottom: 12px;
  text-align: justify;
}

.icons {
  padding: 20px;
  text-align: center;
}

.icons > * {
  margin: 7px;
  color: #8acd01;
}

.Contact, .Projects {
  display: flex;
  flex-direction: column;
  margin: 7px -1px;
  border: solid 0.5px #1a1a1a;
  padding: 30px 80px;
}

.Contact {
  align-items: center;
  text-align: center;
}

.link {
  color: #8acd01;
}

.link:hover {
  color:#1a1a1a;
}

.contact-form > form {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.contact-form > form > input, textarea {
  margin: 3px;
  padding: 5px;
  font-size: 15px;
  border: solid 0.5px #1a1a1a;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 5px;
  resize: vertical;
  max-width: 100%;
}

textarea {
  height: 150px;
}

.btn {
  margin: 3px;
  padding: 5px;
  font-size: 15px;
  font-weight: 400;
  border: solid 0.5px #1a1a1a;
  border-radius: 5px;
  background-color:  #dcdcdc;
  color: #1a1a1a;
}

.btn > a {
  background-color: transparent;
  color: #1a1a1a;
  text-decoration: none;
}

.btn:hover {
  background-color: #8acd01;
  cursor: pointer;
}

.Project {
  margin: 5px 3px;
  text-align: justify;
  align-items: center;
  display: flex;
}

.project-info {
  margin-left: 20px;
}

.description {
  margin: 7px 0;
}

.tech {
  margin: 3px;
  padding: 5px;
  font-size: 15px;
  border: solid 0.5px #1a1a1a;
  border-radius: 5px;
  background-color: #dcdcdc;
}

.green {
  background-color: #8acd01;
}


@media (max-width: 768px) {

  h1 {
    font-size: 1.750em;
    text-align: center;
  }

  .App {
    height: 50vh;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .About, .Projects, .Skills, .Contact {
    flex-direction: column;
    margin: 20px 0;
    padding: 15px;
  }

  .Project {
    flex-direction: column;
    margin: 20px 0;
  }

  .About > div {
    margin: 0;
  }

  .title {
    margin: 0 10px;
  }

  .project-info {
    margin: 0;
  }

  li {
    margin: 10px;
  }

}
